.webtempletselect img{
    width: 100%;
    
    cursor: pointer;
    background: #ffffff;
    box-shadow: 5px 5px 10px #7d7d7d, -5px -5px 10px #ffffff;
}
.webtempletselectmobile img {
    width: 80%;
}
.webtempletimg {
    width: 100%;
    height: 100vh;
    padding-bottom: 20%;
    overflow: auto;
}
.webtempletselect {
    margin: 10px;
    
}

.webtempletimg  .btn{
    font-size: 15px;
    background-color: red;
    font-weight: bolder;
    letter-spacing:0.2em;
    outline: none;
}
.webtempletselect .webtempletselect_selectbtn {
    position: absolute;
    margin-left: 40%;
    bottom:40%;
    display: none;
}

@keyframes btnselect {
    0%{
        bottom:0%;
        opacity:0
    }
    50%{
        bottom:20%;
        opacity: 0.5;
    }
    100%{
        bottom:40%;
        opacity: 1;
    }
}

@media (max-width: 500px)
{
    .webtempletselectmobile img {
        width: 100%;
    }
}