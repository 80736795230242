.main-div {
    width: 100%;
    margin: 0 auto;

    padding: 25px;

    border-radius: 25px;
}
#previewA {
    color: black;
    outline: none;
}
#previewA:hover {
    color: blue;
    text-decoration: none;
}
.dashfirst {
    overflow-y: auto;
    height: 80vh;
    padding-bottom: 80px;
    border-radius: 12px;
}
.ck-editor__editable {
    min-height: 200px;
}
.about_class_li {
    font-weight: 400 !important;
}
.about_class_li ul li,
.projecy_class ul li {
    list-style-type: none !important;
    display: flex !important;
}
.projecy_class ul li .material-icons {
    padding-right: 10px;
}
.first,
.second {
    overflow-y: auto;
    height: 80vh;
    border: 1px solid rgb(165, 165, 165);
    padding-bottom: 80px;
    border-radius: 12px;
}
.label {
    font-weight: 600;
    color: #022e36;
    font-size: 10px;
    display: flex;

    padding: 16px 0 13px 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.main-div h3 {
    color: rgb(0, 0, 0);
    font-weight: bolder;
    text-align: center;
}

.main-div input[type="text"] {
    display: block;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: transparent;
    padding: 15px;
    outline: none;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
}
.borLessInput,
.borLessInputs {
    border-bottom: 1px dotted black !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 5px !important;
}

.borLessInputs {
    width: 50% !important;
}

.main-div input[type="date"],
.main-div input[type="number"],
input[type="email"],
input[type="file"],
select {
    display: block;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 15px;
    outline: none;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
}
input[type="file"] {
    display: none;
}

#pict {
    cursor: pointer;
    padding: 10px;

    background-color: transparent;

    width: 100%;
    border-bottom: 2px solid;

    transition: 0.3s;
}
#pict:hover {
    background-color: grey;
    color: black;
    transition: 0.3s;
}
textarea {
    display: block;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: transparent;
    padding: 15px;
    outline: none;
    width: 100%;

    margin-bottom: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    color: rgb(13, 41, 75) !important;
}
.updateLogo {
    padding-left: 0;
    width: 70%;
}
.editinputLogo {
    float: right;
    width: 50%;
    text-align: center;
}
.dateYear {
    display: flex;
    justify-content: space-between;
}

.modeInfo {
    font-size: 36px;
    margin: 10px 10px 10px 90%;
    font-weight: bolder;
    border: 3px solid black;
    border-radius: 50px;
    cursor: pointer;
}
.btn-edit {
    margin-top: 10px;
    width: 100%;
    font-size: 20px;
    float: none;
    padding: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(3, 28, 66);
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    outline: none !important;
}
.edcopllapse,
.templetcopllapse {
    margin-top: 10px;
    border-radius: 10px;
    background: #f7f5f5;
    box-shadow: 5px 5px 10px #cfcece, -5px -5px 10px #ffffff;
    float: left;
    margin-right: 30px;
}
s {
    font-weight: bold;
    opacity: 0.5;
}
.glyphicon-option-horizontal {
    margin-left: 10px;
    cursor: pointer;
    font-weight: bolder;
    font-size: 25px;
}
/** * Extracted from: SweetAlert * Modified by: Istiak Tridip */
.donabout,
.done,
.doneachive,
.doneexp,
.doneprofmem,
.doneproj,
.donepub,
.doneresearch,
.doneskill,
.doneteach,
.donetrainc,
.doneworkshop {
    position: absolute;
    padding-left: 45%;
    padding-top: 20%;
    background-color: hsla(0, 0%, 0%, 0.2);
    z-index: 123;
    display: none;
}
.success-checkmark {
    width: 100%;
    height: 100%;
    margin-top: -3vh;
}
.success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;

}
.success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 5s ease-in;
}
.success-checkmark .check-icon::after,
.success-checkmark .check-icon::before {
    content: "";
    height: 100px;
    position: absolute;
    background: #fff;
    transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #4caf50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 2s;
}
.success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 2s;
}
.success-checkmark .check-icon .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;

}
.success-checkmark .check-icon .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #fff;
}
@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}
@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}
@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
*::-webkit-scrollbar {
    width: 10px;

}

/* Track */
*::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: rgb(236, 0, 0);
}
.rating {
    width: 30% !important;

}
.caret {
    font-size: 30px;
    cursor: pointer;
}
.democollapse {
    padding-left: 20px;
}
@media (max-width: 768px) {
    .first,
    .second {
        height: auto;
    }
    .second {
        margin-top: 10px;
    }
}
.content_loading {
  display: block;
  margin: auto;
  text-align: center;
  
}
.content_loading img{
  width: 50%;
  margin-top: 15%;
}
#website_content{
  display: none;
}