.bg-1index {
    clip-path: polygon(0 0, 100% 0, 100% 53%, 0% 100%);
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right, #171717 0%, black 100%);
    border: 1px solid transparent;
}
.bg-img {
    position: fixed;
    z-index: -123;
    width: 100%;
    top: 0;
    opacity: 0.1;
}
h1 {
    overflow: hidden;
}
.navbarindex {
   
    width: 100% !important;
    margin-left: 0 !important;
    padding: 50px;
    transition: 0.5s ;
}
.navbarindex .navbar-brandmain img {
    width: 15%;
    position: absolute;
    margin-top: -15px;
}
.bg-1index h1 {
    padding-left: 8%;
    padding-top: 13%;
    font-size: 7em;
    text-transform: uppercase;
    font-weight: bolder;
    color: #f9fcac;
}
.bg-1index p {
    padding-left: 8%;
    font-size: 2em;
    text-transform: uppercase;
}
.btn-pagecreate {
    margin: 4% 4% 4% 9%;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
    outline: none !important;
    background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
    border: none;
    font-weight: bolder;
    color: rgb(0, 0, 0);
}
.formPage {
    padding-left: 20%;
}
.bg-2main,
.bg-3main {
    margin-top: 5%;
    padding-bottom: 70px;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.bg-2main-second {
    border-radius: 10px;
    height: 50vh;
    background: #72b2fb;
}

.bg-3 {
    padding: 30px;
}
.headline {
    width: 100%;
}
.headline h2 {
    font-size: 80px;
    letter-spacing: 0.2em;
    padding-bottom: 15%;
    padding-top: 15%;
    font-family: "Comfortaa" !important;
    font-weight: bolder;
    text-transform: uppercase;
}
.bg-3 #imgbg32 {
    border-radius: 30px;
}
.bg-3 #imgbg32 img {
    width: 90%;
    border-radius: 30px;
}
.bg-3 #imgbg31 img {
    width: 70%;
    border-radius: 30px;
    float: right;
}
.bg-3 #imgbg31 {
    border-radius: 30px;
    box-shadow: 2px 5px 5px white;
}

.bg-3 p {
    text-align: center;
    font-size: 20px;
}

.bg-2main h3 {
    font-size: 30px;
    color: #000000;
    font-weight: bolder;
    margin-bottom: 30px;
}
.bg-2main p {
    width: 80%;
    color: #000000;
    font-size: 20px;
}

.features-bg-img {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 20vh;
    cursor: pointer;
}
.features-bg-img3 {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.imgbg2 {
    height: 40vh;
}
.bg-2maincontent {
    margin: 50px 10px;
}
.bg2Box,
.bg3Box {
    text-align: center;
    margin-top: 10%;
}

.w3-animate-bottom {
    position: relative;
    animation: animatebottom 1s;
}
@keyframes animatebottom {
    from {
        left: 100px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}
.w3-animate-bottom1 {
    position: relative;
    animation: animatebottom1 1s;
}
@keyframes animatebottom1 {
    from {
        left: -100px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}
.bg-4 {
    padding-top: 10%;
}

.bg-4 p {
    font-size: 15px;
    cursor: pointer;
}
.borderbocshadow1 {
    border-radius: 10px;
    width: 80%;
}
#w1,
#w2,
#w3,
#w4 {
    font-weight: bold;
    color: rgb(255, 255, 255);
}

#w1 img,
#w2 img,
#w3 img,
#w4 img {
    width: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.w1Toggle,
.w2Toggle,
.w3Toggle,
.w4Toggle {
    color: #15bad3 !important;
    font-weight: bold;
}

.bg-4 .vl {
    border-left: 20px solid rgb(221, 220, 220);
    height: 50px;
}
.dash {
    width: 20%;
    background-color: #b14b32;
    border: 1px solid #b14b32;
}
.bg-3 h1,
.bg-4 h1,
.bg-5 h1,
.bg-6 h1 {
    font-weight: bolder;
    font-size: 40px;
    color: #000000;
}

.bg-who {
    margin-top: 5%;
}
.bg-who h1 {
    font-weight: bolder;
    font-size: 40px;

    color: #000000;
}
.bg-who p {
    color: black;
    font-size: 20px;
}
.bg-7-1 {
    width: 100%;

    margin-top: 8%;
}
.bg-7-1 h1 {
    font-size: 30px;
    color: #000000;
    font-weight: bolder;
    padding-top: 50px;
}
.bg-7-1 .contentno {
    color: white;
    margin-top: 40%;
    padding-left: 19%;
    padding-right: 15%;
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
}
.bg-7-1 .bg7content {
    text-align: center;
    color: rgb(0, 0, 0);
    margin-top: 10%;
    font-size: 25px;
}
.bg-7-1 .contentno .v {
    cursor: pointer;
    color: rgb(9, 26, 46);
}
.bg-7-1 .contentno .dashcon {
    border: 1px solid rgb(9, 26, 46);
}
.btn-pagecreate1 {
    margin: 4% 4% 4% 45%;
    font-size: 15px;
}
#h1 {
    color: black;
    font-weight: bolder;
    font-size: 40px;
    letter-spacing: 0.3em;
    padding-bottom: 50px;
}

.modalContent {
    color: black;
}

.modalContent p {
    font-size: 40px;
    color: red;
    margin-right: 0;
    font-weight: bolder;
}
.modalContentbody {
    margin-top: 10%;
}

.modal-open {
    padding-right: 0 !important;
}

.cvtemplet {
    width: 15%;
    position: absolute;
    z-index: 123;
    margin-top: -10%;
    left: 80%;
    opacity: 0.5;
}
.bg-who-second {
    padding-top: 30px;
}
.bg-who-second p {
    font-weight: bold;
    font-family: "Comfortaa" !important;
}
.featuredetail {
    height: 15vh;
}
.featuredetail p {
    font-weight: bold;
    letter-spacing: 0.1em;
    font-family: "Roboto" !important;
}
.bg3Box p {
    font-size: 20px;
}
#mainindexcontent {
    display: none;
}
#loadindex {
    height: 100vh;
}
.webfeaturebox {
    width: 100%;
    display: flex;
}
.webfeaturebox h3 {
    font-size: 20px;

    cursor: pointer;
}
.webfeaturebox img {
    width: 100%;
    height: 20vh;
}
.webfeaturedetail {
    height: 50vh;
}
.webfeaturedetail img {
    width: 60%;
    opacity: 0.3;
}
.webfeaturedetail p {
    font-size: 30px ;
    position: absolute;
    top: 40%;
    font-weight: bolder;
    letter-spacing: 0.2em;
}
.bg-3main_content {
    padding-left: 10%;
    padding-right: 10%;
}
.containerload {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4em;
    filter: contrast(20);
    background-color: black;
    overflow: hidden;
}

.loadtext {
    color: white;
    font-size: 8rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    filter: blur(0.5rem);
    animation: letterspacing 8s infinite alternate cubic-bezier(0.2, 0, 0, 1);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
@keyframes letterspacing {
    0% {
        letter-spacing: -5rem;
        filter: blur(0.5rem);
    }

    50% {
        filter: blur(0.5rem);
    }

    100% {
        letter-spacing: 1rem;
        filter: blur(1.5rem);
    }
}
.navbar-rightindex li a{
    font-size: 20px !important;
    color:white !important;
    font-weight:bolder;
    text-transform: uppercase;
    font-family: 'Comfortaa';
}

@media (min-width: 500px) and (max-width:1024px) {
    .bg-1index {
        clip-path: none;
        height: auto;
        padding-bottom: 10%;
        padding-top: 10%;
    }
    .features-bg-img {
        width: 100%;
        height: 10vh;
    }
    .webfeaturebox h3 {
        font-size: 30px;
    }
    .bg-3main_content {
        padding-left: 0%;
        padding-right: 0%;
    }
    .navbar-rightindex li a{
        font-size: 30px;
    }
    .navbarindex .navbar-brandmain img {
        width: 25%;
    }
   
}
@media (max-width: 500px) {
    .navbarindex{
        background-color: black;
    }
    .navbarindex{
        padding: 20px;
    }
        .loadtext {
        font-size: 6rem;
    }
    #h1 {
        color: black;
        font-weight: bolder;
        font-size: 25px;
        letter-spacing: 0.1em;
        padding-bottom: 0;
        padding-top: 30px;
    }
    .bg-1index {
        clip-path: none;
        height: auto;
        padding-bottom: 10%;
    }
    .navbarindex .navbar-brandmain img {
        width: 50%;
    }
    .bg-1index h1 {
        text-align: center;
        padding-left: 0;
        padding-top: 40%;
        clip-path: none;
        font-size: 3em;
    }
    .bg-1index p {
        text-align: center;
        padding-left: 0;
        font-size: 1em;
    }
    .btn-pagecreate {
        margin: 4% 4% 4% 25%;
        font-size: 15px;
    }
    .btn-pagecreate1 {
        margin: 4% 4% 4% 20%;
        font-size: 15px;
    }
    .bg-img {
        height: 100vh;
        width: 200%;
        opacity: 0.05;
    }
    .bg-who {
        padding-bottom: 20px;
    }
    .bg-who img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .bg-who p {
        text-align: justify;
    }
    .bg2Box h3,
    .bg3Box h3 {
        text-align: center;
    }
    .bg2Box,
    .bg3Box {
        padding-bottom: 10px;
        border-bottom: 1px solid black;
    }
    .bg-2main p {
        width: 100%;
        color: #000000;
        font-size: 15px;
        text-align: justify;
    }
    .headline h2 {
        font-size: 25px;
    }
    .bg-3 h1 {
        font-size: 20px;
        text-align: center;
    }

    .bg-3 #imgbg32 {
        border-radius: 10px;
        padding-bottom: 30px;
    }

    .bg-3 #imgbg32 img {
        width: 100%;
        border-radius: 10px;
    }
    .bg-3 #imgbg31 {
        border-radius: 0;
        padding-bottom: 30px;
    }
    .bg-3 #imgbg31 img {
        width: 100%;
        float: right;
        border-radius: 10px;
    }
    .featuredetail p {
        text-align: center;
    }
    .cvfeatureh3 h3 {
        font-size: 15px;
        height: 5vh;
    }

    .features-bg-img {
        width: 100%;

        height: 5vh;
    }
    .navbarindex {
        border: 1px solid #161616 !important;
    }
    .bg-3main_content {
        padding-left: 0%;
        padding-right: 0%;
    }
    .webfeaturedetail p {
        font-size: 20px ;
    }
    .webfeaturedetail img{
        width: 100%;margin-top:0%;
    }
    .webfeaturebox h3 {
        font-size: 15px;
    }
}