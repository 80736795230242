.cvtempletselect img {
    width: 100%;
    height: 50vh;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 5px 5px 10px #7d7d7d, -5px -5px 10px #ffffff;
}

.cvtempletimg {
    width: 100%;
    height: 100vh;
    padding-bottom: 20%;
    overflow: auto;
}
.cvtempletselect {
    margin: 10px;
    height: 50vh;
}
.cvtempletselect .cvtempletselect_selectbtn .btn{
    font-size: 15px;
    background-color: red;
    font-weight: bolder;
    letter-spacing:0.2em;
    outline: none;
}
.cvtempletselect .cvtempletselect_selectbtn {
    position: absolute;
    margin-left: 25%;
    bottom:40%;
    display: none;
}
.cvtempletselect:hover .cvtempletselect_selectbtn{
    display: block;
    animation:btnselect 0.2s ease-in-out;
}
@keyframes btnselect {
    0%{
        bottom:0%;
        opacity:0
    }
    50%{
        bottom:20%;
        opacity: 0.5;
    }
    100%{
        bottom:40%;
        opacity: 1;
    }
}
.cvtempletselect:hover img{
    opacity:0.2
}
@media (min-width: 500px) and (max-width:1024px) {
    .cvtempletselect img {
        width: 100%;
        height: 40vh;
        cursor: pointer;
        background: #ffffff;
        box-shadow: 5px 5px 10px #7d7d7d, -5px -5px 10px #ffffff;
    }
    .cvtempletimg {
        width: 100%;
        height: 100vh;
        padding-bottom: 50%;
        overflow: auto;
    }
   

}