.welcomeback {
    padding-top: 30px;
   
}
.welcomeback img {
    width: 30%; 
    margin-left: 25%;
}
.welcomeback h3{
    font-size:30px;
    text-align: center;
    margin-right:20%;
    font-family: "Comfortaa";
}
.welcomeback .btn{
    margin-top: 20%;
    margin-left: 5%;
    width: 35%;
    border:2px solid #ccc;
    outline:none;
   font-weight: bold;
   border-bottom-left-radius: 25px;
   border-top-right-radius: 25px;
}
.welcomeback .btn:hover{
    background-color: aliceblue
}
#mainSignupSection{
    display: none;
    animation: op 1s linear;
}
#loadersignup img{
    margin: 15% ;
}
@keyframes op {
    0%{opacity: 0.3;}
    50%{opacity:0.7}
    100%{opacity: 1;}
}