#home {
  color: black;
}
.cropImageSection {
  width: 100%;
}
.finalcropImageSection {
  width: 70%;
}
#pictName img {
  width: 40%;
  border-radius: 25px;
}
.aboutpreview span {
  color: black;
  font-weight: bolder;
  letter-spacing: 0.1em;
  padding-bottom: 30px;

}
.removeabout i,.removeabout span{
  padding-right:10px
}

