.buttonmessage{
    border:1px solid black;
    margin-top: 20px;
    padding: 10px;
    color: black;
   cursor:pointer
}
.buttonmessage p{
    font-size: 20px;
}
.buttonmessage p span
{
    float:right;
    font-size: 13px;
    padding-top: 10px;
}
.messagedelete{
    padding-top: 40px;
    font-size: 20px;
    cursor:pointer
}