.cv1 {
    padding: 40px 20px 80px 20px ;
    font-family: "Roboto" !important;
    font-weight: 100;
   
    color:black
    
}
.cv1 p{
    color: black !important;

}
.bg1cv1 {
    width: 100%;
    text-align: right;
    float: right;
    min-height: 10vh !important;
}


.namecv1 {
   
    color: black !important;

    width: 100%;

    padding: 5px;

}

hr {
    background-color: rgb(207, 207, 207 ) !important;
    border: 1px solid rgb(207, 207, 207) !important;
}

.bg-6cv1 table tr td,
.bg-6cv1 table tr th {
    padding: 15px 10px;
    font-size: 10pt !important;
    border: 1px solid;
}
.cvleft{
    width: 30%;
    float: left;
    position: relative;
}
.cvright{
    width: 70%;
    float: right;
}
.p
{
    font-size: 10pt;
    color: rgb(0, 0, 0);
    font-weight: bolder;
    text-transform: uppercase;
    
}
.p::after{
    content: " :-";
}


.bg-3cv1 li,
.bg-6cv1 {
    font-size: 10pt ;
}
table{
    width: 100%;
    font-size: 11pt ;
}

@media print {
    body {

        -webkit-print-color-adjust: exact !important;

    }

    .bg2cv1 p {
        text-transform: uppercase;
        font-size: 10pt !important;
        width: 100%;

        padding: 5px;
        background-color: rgb(207, 207, 207) !important;
        -webkit-print-color-adjust: exact !important;

    }

}
@media print {
    .page-break {
        display: block;
        page-break-before: always;
    }
}
.cvfirst {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.cvfirst::-webkit-scrollbar {
    width: 10px;
}

.cvfirst::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.cvfirst::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.cvfirst::-webkit-scrollbar-thumb:hover {

    background: #555;
}

.cvfirst1 {
    font-size: 10pt ;
    color: black;
}


.cvfirstDivBox {
    height: 10vh;
    width: 100%;
    padding: 10px;
    border: 1px solid #aaaaaa;
    background-color: white;
    color: black;
}

.cvfirstDivBox p {
    font-size: 10pt ;
    color: black;
    width: fit-content;
}

.cvfirst1DivBox {
    height: 5vh;
    width: 100%;
    padding: 10px;
    border: 1px solid #aaaaaa;
    color: black;
}

.cvfirst0DivBox {
    height: 3.3333333333333333333vh;
    padding: 5px;
}

.cvzero {
  

    color: rgb(0, 0, 0);
}

.cvzero2 {

    height: 10vh;
    text-align: right;
    padding-right: 15px;
    font-size: 10pt !important;
}

.cvzero1 {
    background-color: rgb(168, 168, 168) !important;
    font-size: 10pt !important;
    padding: 10px;
}


.cvfirst0DivBox p {
    font-size: 10pt !important;
    text-align: center;

}

.cvfirst1DivBoxp {
    cursor: grab;
}
