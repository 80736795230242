#dashboardmain {
    margin: 0;
    overflow: hidden;
    display: none;
    transition: 0.5s;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
}
a {
    color: white;
    text-decoration: none !important;
    cursor: pointer;
}
a:hover {
    color: white;
}
.sectionDiv {
    height: 100vh;
    color: white;
    background-color: #000;
}
.leftSection {
    float: left;
    width: 15%;
    background-color: rgb(0, 0, 0);
    padding-bottom: 10%;
    padding-top: 10px;
}
.leftSection #makeyacvlogo {
    width: 80%;
    margin: 10px;
}
.middleSection {
    width: 83%;
    float: left;
    background-color: rgb(255, 255, 255);
    position: relative;
}
.mainSectionMiddle {
    margin-top: 12vh;
    padding-bottom: 30%;
    height: 90vh;
    overflow: hidden;
}
.rightSection {
    float: right;
    width: 2%;
}
.bottomNavbar p {
    color: white;
    float: right;
    letter-spacing: 0.3em;
    padding: 5px;
    font-weight: bolder;

}
.bottomNavbar p:nth-child(2){
    font-size: 12px;
    padding: 8px;
}
.middleSection .topNavbar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10vh;
    background-color: #000;
}
.middleSection .topNavbar p {
    color: white;
}

.middleSection .bottomNavbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5vh;
    background-color: rgb(0, 0, 0);
}
.leftsidemenuList {
    padding-top: 30px;
    overflow-y: auto;
    height: 82vh;
    padding-bottom: 50px;
    transition: 0.5s linear;
}
/* width */
.leftsidemenuList::-webkit-scrollbar {
    width: 10px;

}

/* Track */
.leftsidemenuList::-webkit-scrollbar-track {
    background: #000000;
}

/* Handle */
.leftsidemenuList::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
}

/* Handle on hover */
.leftsidemenuList::-webkit-scrollbar-thumb:hover {
    background: rgb(236, 0, 0);
}
.navmenu {
    padding: 10px;
    display: flex;
    font-weight: bolder;
    border-bottom-left-radius: 25px 25px;
    border-top-left-radius: 25px 25px;
}
.navmenu a:nth-child(2) {
    padding: 5px;

}

#addsection a {
    border: 2px solid white;
    margin-top: 10px;
    border-radius: 25px;
    cursor: pointer;
    color: white;
}
#addsection a:hover {
    color: black;
}
#userIcontop img {
    cursor: pointer;
    width: 40px;
}
#userIcontop a:hover {
    background-color: transparent !important;
}
.ios-switch {
    display: none;
    position: relative;
}

.ios-switch + label {
    display: block;

    padding: 10px;
    text-align: left !important;
}

.ios-switch + label span.sw {
    width: 46px;
    height: 27px;
    float: right;
    border-radius: 16px;
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid #ffffff;
    background-color: rgb(235, 235, 235);
    transition: all ease 300ms;
    cursor: pointer;
}
.ios-switch + label span.sw:before {
    content: "";
    position: absolute;
    background-color: #fff;
    margin-top: 0;
    margin-left: 0;
    height: 23px;
    width: 23px;
    border-radius: 15px;
    transition: all ease 300ms;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.ios-switch:checked + label span.sw {
    background-color: rgb(161, 156, 156);
}
.ios-switch:checked + label span:before {
    margin-left: 19px;
    background-color: rgb(12, 33, 58);
}
.ios-switch:checked .sectionItem {
    background-color: rgb(210, 255, 210);
}
.modalContent p {
    font-size: 25px;
    font-weight: bolder;
}
.modalclose {
    font-weight: bolder;
    font-size: 25px;
    cursor: pointer;
}
.dashbox {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: -5px 5px 10px #7d7d7d, 5px -5px 10px #ffffff;
    width: 90%;
    height: 50vh;
    margin: 20px;
    padding: 2px 10px 10px;
}
.dashboxjob {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: -5px 5px 10px #7d7d7d, 5px -5px 10px #ffffff;
    width: 98%;
    height: 50vh;
    margin: 20px 20px 20px 10px;
    padding: 2px 10px 10px;
}
.dashbox h1,
.dashboxjob h1 {
    text-align: center;
    letter-spacing: 0.2em;
    font-weight: bold;
    padding: 0 5px 5px;
    font-size: 15px;
    color: black;
}
.dashbox .btn-notify {
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    background: #ffffff;
    text-align: center;
    border: none;
    outline: none;
    padding: 5px;
}
.dashbox #messagenotificationPreview,
.dashbox #profilenotificationPreview {
    padding-top: 16px;
    margin-top: 15px;
    height: 33vh;
    overflow-y: auto;

}
.dashbox #activitylog {
    padding-top: 16px;
    margin-top: 10px;
    height: 40vh;
    overflow-y: auto;
}
.dashbox {
    margin-bottom: 50px;
    overflow-y: none;
    color: rgb(0, 0, 0);
    font-weight: bold;
}
.dashbox .dashsat {
    overflow-y: auto;
    height: 40vh;
}
.dashbox .profiledstaticticcount {
    padding: 2px;
    margin: 15px 10px 10px;
    width: 100%;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: -5px 5px 7px #ededed, 5px -5px 7px #ffffff;
    text-align: center;

}
.dashbox .profiledstaticticcount p {
    font-size: 12px;
    color: #585858;
}
.dashbox .profiledstaticticcount span {
    font-size: 25px;
    color: #000000;
    padding-left: 5px;
    font-weight: bolder;
}
.dashbox #messagenotificationPreview::-webkit-scrollbar {
    width: 10px;
}
.dashbox #messagenotificationPreview::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.dashbox #messagenotificationPreview::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
.dashbox #messagenotificationPreview::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.msgnotifyVal {

    display: flex;
}
.msgnotifyVal span {

    margin-left: 60px;
    margin-top: 10px;
}
.dashbox .msgnotifyVal label {
    font-size: 10px;
}
.menulistptag{
    font-size:20px !important;

}
#responwarn{
    display:none
}
#generatesite{
    font-size:20px;
    text-align:center;
    padding-top: 50px;
}
.progress1 {
    position: absolute;
    height: 160px;
    width: 160px;
    border: none;
    top: 50%;
    left: 50%;
    margin: -80px 0 0 -80px;
    -webkit-animation-name: animatebottom123;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom123;
    animation-duration: 1s;
}
@-webkit-keyframes animatebottom123 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes animatebottom123 {
    from {
        bottom: -100px;
        opacity: 0;
    }
    to {
        bottom: 0;
        opacity: 1;
    }
}
.progress-circle {
    transform: rotate(-90deg);
    margin-top: -40px;
}

.progress-circle-back {
    fill: none;
    stroke: #d2d2d2;

    stroke-width: 10px;
}

.progress-circle-prog {
    fill: none;

    stroke-width: 10px;
    stroke-dasharray: 0 999;
    stroke-dashoffset: 0;
    transition: stroke-dasharray 0.7s linear 0s;
}
#calculating {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: red;
    padding-top: 25%;
}
#unreadMsgNotify {
    color: red;
    font-weight: bolder;
    padding: 5px;
    opacity: 1 !important;
}
#coming {
    text-align: center;
    padding-top: 10%;
    font-size: 50px;
    color: black;
    animation: change 1.5s infinite;
    font-weight: bolder;
}
@keyframes change {
    0% {
        color: black;
        transform: scale(0.9);
    }
    50% {
        color: red;
        transform: scale(1);
    }
    100% {
        color: black;
        transform: scale(0.9);
    }
}
#userIcontop .dropdown-menu {
    margin-top: 20px;

}
#userIcontop .dropdown-menu li {
    padding: 5px 5px 5px 0;
}
#userIcontop .dropdown-menu li:hover {
    background-color: rgb(190, 190, 190);

}
#userIcontop .dropdown-menu li a {
    font-weight: bold;
}
.chatsection {
    height: 60vh;
    width: 30%;
    border-radius: 20px;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    background-color: rgb(0, 0, 0);
    border: 5px solid black;
    animation: bottomtotop 0.5s linear;
    margin: 10% 0 10% 65%;
}
@keyframes righttoleft {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}
@keyframes bottomtotop {
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
}
.frinendlistsection {
    height: 80vh;
    width: 20%;
    border-radius: 20px;
    position: absolute;
    z-index: 1;
    background-color: rgb(236, 236, 236);
    right: 0;
    animation: righttoleft 0.4s linear;
    margin: 10px 3% 10% 10%;
    padding: 5px;
}
.frinendlistsection .material-icons {
    color: black;
    padding: 5px;
    cursor: pointer;
    width: fit-content;
}
.frinendlistsection .form__group {
    padding: 10px 10px 10px 30px;
}
.frinendlistsection .form__field {
    color: black !important;

}
.frinendlistsection .frindlistname {

    margin-top: 10px;
    height: 46vh;
    border-radius: 20px;
    overflow-y: auto;
}
.frinendlistsection .frindlistname li {
    color: black;
    list-style-type: none;
    padding: 10px 10px 10px 15px;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
}
.frinendlistsection .frindlistname li img {
    width: 25%;
    padding-right: 5px;
    border-radius: 50px;
}
.notificationicon {
    padding-top: 7px;
    margin: 5px;
    width: fit-content;

    margin: 10px 20px 5px 5px;
    cursor: pointer;
}
.notificationicon .material-icons {
    font-size: 40px;
}
.notificationicon .newmaterial-icons {
    font-size: 40px;
    animation: shownoti 1s linear infinite;
}
@keyframes shownoti {
    0% {
        color: rgb(33, 221, 16);
        transform: scale(1);
    }
    50% {
        color: rgb(247, 184, 12);
        transform: scale(1.1);
    }
    100% {
        color: rgb(255, 255, 255);
        transform: scale(1);
    }
}
.chatsection-header {
    display: flex;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-right: 10px;
    background-color: rgb(238, 238, 238);
}
.chatsection-header .material-icons {
    color: black;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: 5px;
    color: blue;
    font-weight: bolder;
}
.mainChatindi {
    padding: 10px 20px 60px;
    height: 45.3vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: rgb(255, 255, 255);

}
.mainChatindi::-webkit-scrollbar {
    width: 0.45rem;
}

.mainChatindi::-webkit-scrollbar-track {
    background: transparent;
}

.mainChatindi::-webkit-scrollbar-thumb {
    background: #727473;
}
.indiForm {
    height: 8vh;
    border-top: 1px solid #2c2c2c;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    background-color: #2c2c2c;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
}

.indiForm button {
    width: 10%;
    margin-left: 20px;
    padding-right: 35px;
    background-color: rgb(255, 255, 255);
    border-bottom-right-radius: 20px;
}
.material-icons.micon {
    color: black;
}

.indiForm textarea {
    width: 90%;
    font-size: 1em;
    background: rgb(253, 253, 253);
    color: rgb(0, 0, 0);
    outline: none;
    border: none;
    margin: 5px;
    border-radius: 20px;
    padding: 10px;
    resize: none;

}

.showPickerDivindi {
    border-bottom-left-radius: 20px;

    cursor: pointer;
    background-color: #2c2c2c;
}
.showPickerDivindi .material-icons {
    color: white;
    padding: 15px 15px 20px;
}
#pict {
    cursor: pointer;
    background-color: transparent;
}

#pict input {
    background-color: transparent;
}
#pict:hover {
    background-color: green !important;
}
.message {
    display: flex;
    align-items: center;

}
.message p {
    font-size: 14px;
    padding: 5px 5px 0;
    font-weight: bold;
    width: 50%;
    word-wrap: break-word;
}
.message p label {
    float: right;
    font-size: 8px !important;
    color: rgb(58, 3, 3) !important;
}
.message label {
    color: black !important;
    font-size: 10px;
}
.sent {
    flex-direction: row-reverse;

}

.sent p {
    color: rgb(0, 0, 0);
    background: #30a4f7;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 20px;
}
.received p {
    background: #e5e5ea;
    color: black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 20px;

}
.chatsection-username img {
    width: 30px;
    MARGIN: 5px;
    border-radius: 50px;
}
.chatsection-username {
    color: black !important;
    font-weight: bolder;
}
.frinendlistsectionselectbtn .btn {
    width: 100% !important;
}
.frinendlistsectionmenusection {
    width: 100%;
    padding: 3px;
    color: black;
}
.frinendlistsectionmenusection_div {
    width: 50%;
    background: #ECECEC;
    box-shadow: 5px 5px 7px #a8a8a8, -5px -5px 7px #ffffff;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
}
.frinendlistsectionmenusection_div p {
    padding-top: 5px;
    font-weight: bold;
}
.frinendlistsectionmenusection_first {
    float: left;
}
.frinendlistsectionmenusection_second {
    float: right;
}
.frinendlistsectionmenusection_selectsection {
    padding-top: 20px;
}
.recentchatsection {
    margin-top: 10px;
}
.recentchatsection li {
    color: black;
    margin: 10%;
    list-style-type: none;
    font-weight: bold;
    cursor: pointer;
}
.recentchatsection li img {
    width: 50px;
    border-radius: 50px;
}
.add {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
}
.blinkspan {
    animation: blinknoti 0.9s infinite;
}
@keyframes blinknoti {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.5;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.picker {
    position: absolute;
    z-index: 12;
    top: 9%;
    left: 3%;
    opacity: 1;
}
.cvtempletselect img {
    width: 100%;
    height: 50vh;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 5px 5px 10px #7d7d7d, -5px -5px 10px #ffffff;
}

.cvtempletimg {
    width: 100%;
    height: 100vh;
    padding-bottom: 20%;
    overflow: auto;
}
.cvtempletselect {
    margin: 10px;
    height: 50vh;
}
.cvtempletselect .cvtempletselect_selectbtn .btn {
    font-size: 15px;
    background-color: red;
    font-weight: bolder;
    letter-spacing: 0.2em;
    outline: none;
}
.cvtempletselect .cvtempletselect_selectbtn {
    position: absolute;
    margin-left: 25%;
    bottom: 40%;
    display: none;
}
.cvtempletselect:hover .cvtempletselect_selectbtn {
    display: block;
    animation: btnselect 0.2s ease-in-out;
}
@keyframes btnselect {
    0% {
        bottom: 0;
        opacity: 0;
    }
    50% {
        bottom: 20%;
        opacity: 0.5;
    }
    100% {
        bottom: 40%;
        opacity: 1;
    }
}
.cvtempletselect:hover img {
    opacity: 0.2;
}
.welcomeMsg p:nth-child(1)
{
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.welcomeMsg .btn{
  margin: 15px;
  background-color: #202225;
  color: white;
  outline:none
}
#blockacc{
    display:none
}
.blockacc_container{
    width: 50%;
    
    height: 30vh;
    text-align: center;
    border-radius: 50px;
    padding:10px;
background: #ffffff;
box-shadow:  11px 11px 22px #d9d9d9,
             -11px -11px 22px #ffffff;
             margin: 10% 10% 10% 25%;
}
.blockacc_container p{
    padding-top: 5%;
}