
#loader{
    background-color: black;
    overflow: hidden;
    height: 100vh;
}

#loader {
    width: 100%;
    height: 100%;
    background-color: black;
    position: fixed;
    z-index: 123;
    display: none;
  }
  
  #loader h1 {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  
    font-weight: bolder;
    font-size: 1em;
    letter-spacing: 0.7em;
  }
  
  #connected {
   visibility: hidden;
  }
  #loader h1 span {
    -webkit-text-fill-color: black;
    /* Will override color (regardless of order) */
    
    animation: animate 6s forwards linear;
  }
  
  @-moz-keyframes animate {
    0% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    100% {
      -webkit-text-fill-color: white;
    }
  }
  
  @-webkit-keyframes animate {
    0% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    100% {
      -webkit-text-fill-color: white;
    }
  }
  
  @-o-keyframes animate {
    0% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    100% {
      -webkit-text-fill-color: white;
    }
  }
  
  @keyframes animate {
    0% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    25% {
      -webkit-text-fill-color: white;
    }
  
    100% {
      -webkit-text-fill-color: white;
    }
  }
  
  #loader h1 span:nth-child(1) {
    animation-delay: 0.8s;
  }
  
  #loader h1 span:nth-child(2) {
    animation-delay: 1.8s;
  }
  
  #loader h1 span:nth-child(3) {
    animation-delay: 2.8s;
  }
  
  #loader h1 span:nth-child(4) {
    animation-delay: 3.8s;
  }
  
  #loader h1 span:nth-child(5) {
    animation-delay: 3.8s;
  }
  
  #loader h1 span:nth-child(6) {
    animation-delay: 2.8s;
  }
  
  #loader h1 span:nth-child(7) {
    animation-delay: 2.8s;
  }
  
  #loader h1 span:nth-child(8) {
    animation-delay: 2.8s;
  }
  
  #loader h1 span:nth-child(9) {
    animation-delay: 2.8s;
  }
  
  #loader h1 span:nth-child(10) {
    animation-delay: 2.8s;
  }
  
.stage {
  width: 100%;
  height: 100%;
  padding-top: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  position: relative;
  color: #fff;
  font-size: 7rem;

  letter-spacing: 1rem;
  padding-top: 0.65rem;
  padding-left: 0.7rem;
  padding-right: 0.26rem;

  padding-bottom: 0.2rem;
}
.slash {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-54%, -46%) rotate(0deg) scaleY(0);
  transform-origin: center center;
  width: 0.05rem;
  height: 145%;
  background: #f9fcac;
  z-index: 4;
  animation: slash 6s ease-in infinite;
}
.slash:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-54%, -46%);
  width: 1.5rem;
  height: 130%;
  background: #f9fcac;
  z-index: -1;
}
.slash:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: #f9fcac;
}
.sides {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.sides .side {
  position: absolute;
  background: #f9fcac;
}
.sides .side:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 0.15rem;
  transform: translateX(-101%);
  animation: side-top ease 6s infinite;
}
.sides .side:nth-child(2) {
  top: 0;
  right: 0;
  width: 0.15rem;
  height: 100%;
  transform: translateY(-101%);
  animation: side-right ease 6s infinite;
}
.sides .side:nth-child(3) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.15rem;
  transform: translateX(101%);
  animation: side-bottom ease 6s infinite;
}
.sides .side:nth-child(4) {
  top: 0;
  left: 0;
  width: 0.15rem;
  height: 100%;
  transform: translateY(101%);
  animation: side-left ease 6s infinite;
}
.text {
  position: relative;
}
.text--backing {
  opacity: 0;
}
.text--left {
  position: absolute;
  top: 0;
  left: 0;
  width: 51%;
  height: 100%;
  overflow: hidden;
}
.text--left .inner {
  transform: translateX(100%);
  animation: text-left 6s ease-in-out infinite;
}
.text--right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.text--right .inner {
  transform: translateX(-200%);
  animation: text-right 6s ease-in-out infinite;
}
@-moz-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@-webkit-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@-o-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@keyframes slash {
  0% {
    transform: translate(-54%, -46%) rotate(0deg) scaleY(0);
  }
  6% {
    transform: translate(-54%, -46%) rotate(0deg) scaleY(1);
  }
  13% {
    transform: translate(-54%, -46%) rotate(0deg) scaleY(1);
  }
  16.6% {
    transform: translate(-54%, -46%) rotate(0deg) scaleY(0);
  }
  100% {
    transform: translate(-54%, -46%) rotate(0deg) scaleY(0);
  }
}
@-moz-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-webkit-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-o-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@keyframes text-left {
  0% {
    transform: translateX(30%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-moz-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-webkit-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-o-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@keyframes text-right {
  0% {
    transform: translateX(-150%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-moz-keyframes side-top {
  0%,
  14% {
    transform: translateX(-101%);
  }
  24%,
  55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-webkit-keyframes side-top {
  0%,
  14% {
    transform: translateX(-101%);
  }
  24%,
  55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-o-keyframes side-top {
  0%,
  14% {
    transform: translateX(-101%);
  }
  24%,
  55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes side-top {
  0%,
  14% {
    transform: translateX(-101%);
  }
  24%,
  55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-moz-keyframes side-right {
  0%,
  14%,
  23% {
    transform: translateY(-101%);
  }
  30%,
  62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-webkit-keyframes side-right {
  0%,
  14%,
  23% {
    transform: translateY(-101%);
  }
  30%,
  62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-o-keyframes side-right {
  0%,
  14%,
  23% {
    transform: translateY(-101%);
  }
  30%,
  62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@keyframes side-right {
  0%,
  14%,
  23% {
    transform: translateY(-101%);
  }
  30%,
  62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-moz-keyframes side-bottom {
  0%,
  14%,
  24%,
  28% {
    transform: translateX(101%);
  }
  37%,
  70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-webkit-keyframes side-bottom {
  0%,
  14%,
  24%,
  28% {
    transform: translateX(101%);
  }
  37%,
  70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-o-keyframes side-bottom {
  0%,
  14%,
  24%,
  28% {
    transform: translateX(101%);
  }
  37%,
  70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@keyframes side-bottom {
  0%,
  14%,
  24%,
  28% {
    transform: translateX(101%);
  }
  37%,
  70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-moz-keyframes side-left {
  0%,
  14%,
  24%,
  34%,
  35% {
    transform: translateY(101%);
  }
  44%,
  79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@-webkit-keyframes side-left {
  0%,
  14%,
  24%,
  34%,
  35% {
    transform: translateY(101%);
  }
  44%,
  79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@-o-keyframes side-left {
  0%,
  14%,
  24%,
  34%,
  35% {
    transform: translateY(101%);
  }
  44%,
  79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@keyframes side-left {
  0%,
  14%,
  24%,
  34%,
  35% {
    transform: translateY(101%);
  }
  44%,
  79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@media (max-width: 500px){
  .stage {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
  }

}
@media (min-width: 500px) and (max-width: 1024px) {
  .stage {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
  }
  .text{
    font-size: 3em !important; 
}
}