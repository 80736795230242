
.skillcolor  {
    border: 1px solid;
    padding: 5px;
    border-top-left-radius: 25px 25px;
    border-bottom-right-radius: 25px 25px;
    padding-left: 10%;
    transition: 0.1s;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  
    background-repeat: no-repeat;
}

