.navbar {
    background-color: transparent;
    border: none;
    margin-left: 15%;
    margin-right: 15%;
   
   
}

.box {
    position: relative;
    background-image: linear-gradient(to right, #171717 0%, black 100%);
    width: 100%;
    height: 100vh;
    overflow: hidden;
   
}

.container-box {
    width: 100%;
    height: 50vh;
    border-radius: 10px;
    margin-top: 90px;
    visibility: visible;
    overflow: hidden;
}

.container-box-login {
    width: 25%;
    height: 82vh;
    background-color: #f7f5f5;
    border-radius: 10px;
    position: absolute;
    transform: translate(180%, -73%);
    padding-left: 57px;

    padding-bottom: 60px;

}
@media (max-width:768px) {
    
}

.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;
}

.form__label__error {
    float: right;
    color: red;
    font-size: 10px;
    display: none;
    font-weight: bold;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
}
.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #11998e;
    font-weight: 700;
}

#notme {
    cursor: pointer;
}
.navbar-brand img {
    width: 80%;
}
.form__field:invalid,
.form__field:required {
    box-shadow: none;
}

.forget_form,
.login_form,
.signup_form {
    margin-right: 20%;
    margin-top: 10%;
}
.login_form .headText,
.signup_form .headText {
    font-size: 25px;
    font-weight: bolder;
    letter-spacing: 0.2em;
    text-align: center;
    color: rgb(12, 33, 58);
}
.headTextf{
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 0.2em;
    text-align: center;
    color: rgb(12, 33, 58);
}
.headText1{
    font-size: 15px !important; 
}
.forget_form .headText {
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 0.2em;
    text-align: center;
    color: rgb(12, 33, 58);
}
.btn-create {
    padding-top: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: bolder;
}
.btn-account {
    outline: none;
    width: 100%;
    background-color: rgb(12, 33, 58);
    color: white;
    border: none;
    padding: 8px;
}



#forgetid,
.signin,
#signin1 {
    margin: 20px;
    text-align: center;
    cursor: pointer;
    font-weight: bolder;

}
.signin a{
    color:black
}
#login_section_password {
    display: none;
    padding-top: 40px;
}

#accountSection {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s;
    transition: 0.3s;
}
@-webkit-keyframes animatebottom {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes animatebottom {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#accountLoading {
    margin-top: 50%;
}

.CBox {
    display: flex;
    margin-top: 20px;
}

.CBox .marquee {
    width: 90%;
    border-bottom: 1px solid;
    padding: 0;
}

#CaptchaDiv,
#CaptchaDivcreate,
#forgetCaptchaDiv {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    letter-spacing: 0.3em;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: bolder;
}
#toi {
    font-size: 10px;
    left: 25%;

    position: absolute;
    bottom: 10px;
    font-weight: 600;
}
.CBox .glyphicon {
    margin: 10px;
    cursor: pointer;
    font-size: 20px;
}

.marquee p {
    text-align: center;
}

.bgImg img {
    width: 80%;
}

#customBtn {
    display: inline-block;
    background: white;
    color: #444;
    margin-left:10px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}

#customBtn:hover {
    cursor: pointer;
}
#customBtnlogin {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}
#customBtnlogin:hover {
    cursor: pointer;
}
span.label {
    font-family: serif;
    font-weight: normal;
}
span.icon {
    display: inline-block;
    vertical-align: middle;

    height: 42px;
}
span.icon img {
    width: 40px;
    padding-left: 20px;
    padding-top: 10px;
}
span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: "Roboto", sans-serif;
}

#signup_code,
.forget_form #forget_code,
.forget_form #forget_password {
    display: none;
}
#signup_code,
.forget_form #forget_code,
.forget_form #forget_email_check,
.forget_form #forget_password {
    margin-top: 40%;
}
.forget_form #forget_code p {
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 40px;
}
#signup_code {
    margin-right: 15%;
}
#signup_code p {
    text-align: center;
    color: red;
    font-weight: bolder;
    margin-bottom: 40px;
}
@media (max-width: 500px) {
    .vl {
        display: none;
    }

    .content {
        min-height: 90vh;
        padding-top: 10%;
        margin-top: 20px;
    }

    .bgImg img {
        display: none;
    }

    .login_form_password {
        margin-top: 20%;
    }

    .login_form,
    .signup_form {
        margin-right: 10%;
        margin-top: 15%;
    }

    .btn-account {
        margin-bottom: 20px;
    }

    #accountLoading {
        margin-top: 10px;
        width: 80%;
        height: 50vh;
    }

    .wrapper {
        font-size: 2em;
        margin-left: 24%;
    }
    #customBtn{
        margin-left:15px
    }
    .container-box {
        visibility: hidden;
    }

    .container-box-login {
        width: 90%;
        min-height: 80vh;
overflow-y:auto;
        background-color: #f7f5f5;
        border-radius: 10px;
        position: absolute;
        transform: translate(5%, -70%);
        left: 0;
        padding-left: 30px;
        animation: none;
    }
    .navbar-brandsignup img{
        margin-left:30px;

    }
}
@media (min-width: 500px) and (max-width:1024px) {
    .vl {
        display: none;
    }

    .content {
        min-height: 90vh;
        padding-top: 10%;
        margin-top: 20px;
    }

    .bgImg img {
        display: none;
    }

    .login_form_password {
        margin-top: 20%;
    }

    .login_form,
    .signup_form {
        margin-right: 10%;
        margin-top: 15%;
    }

    .btn-account {
        margin-bottom: 20px;
    }

    #accountLoading {
        margin-top: 10px;
        width: 80%;
        height: 50vh;
    }

    .wrapper {
        font-size: 2em;
        margin-left: 24%;
    }
    #customBtn{
        margin-left:29%
    }
    .container-box {
        visibility: hidden;
    }

    .container-box-login {
        width: 60%;
        height: 80vh;

        background-color: #f7f5f5;
        border-radius: 10px;
        position: absolute;
        transform: translate(35%, -60%);
        left: 0;
        padding-left: 30px;
        animation: none;
    }
    .navbar-brandsignup img{
        margin-left:30px;

    }
    .signin{
        font-size: 25px;
    }
    #loadersignup{
        margin-left:20%
    }
    .form__field{
        font-size: 1.7em;
    }
    .form__field:placeholder-shown ~ .form__label {
        font-size: 2rem; 
    }
    
    .form__label { 
        font-size: 2rem;

    }
    .btn-account {
        outline: none;
        width: 100%;
        background-color: rgb(12, 33, 58);
        color: white;
        border: none;
        padding: 8px;
        margin-top: 20px;
        font-size: 25px;
    }
}