.generatewebsite{
    color: black;
    font-weight: bold;
    font-size: 20px;
  
}
.generatewebsite h1{
    padding: 40px;
    letter-spacing:0.2em
}
.generatewebsite input{
    outline: none !important;
}

.btn-preview{
    outline: none !important;
    padding:10px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    width: 20%;
    background-color:rgb(25, 25, 75);
    font-size: 20px;
}
.generatewebsite h3 span{
    margin-left: 10px;
    font-size: 15pt;
    width: fit-content;
    cursor: pointer;
    
    color:rgb(155, 0, 0)
}
.websitecode {
  font-weight: bolder;
}
.previewcode{
    margin-top:10%;
    color:black;
    width: 30%;;
    text-align: center;
}
.previewcode .btn{
    color:rgb(255, 255, 255);
    font-weight:bolder;
    width: 30%;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
}
@media (max-width: 768px) {
    .btn-preview {
        width: 50%;
    }
    .webtempletcopllapse{
        margin: 0% 10% 10% 10% !important;
    }

}
.webtempletcopllapse{
    margin: 5% 10% 10% 20%;
}