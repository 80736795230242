html {
    scroll-behavior: smooth;
}
.webtemplet1 {
    background-color: #100E17;
    width: 100%;
    min-height: 100vh;

}
.webtemplet1 .navbartemp1 {
    width: 100% !important;
    margin-left: 0;
    padding: 5px;
    background-color: #100E17;
    padding: 20px 20px 20px 40px;
}
#webtemplet1_content_img img {

    width: 100%;

}
.selt_webtemplet1 #sociallinks {
    padding-top: 30px;
}
.selt_webtemplet1 #sociallinks a:nth-child(1) {
    padding-left: 0;
}
.selt_webtemplet1 #sociallinks a {
    padding-left: 40px;
}
.navbar-brandTemp1 {
    font-family: 'Rock Salt' , cursive;
    font-size: 25px;
    padding-left: 20px;
}
#webtemplet1_content_img {
    width: 50%;
    display: block;
    margin: 60px auto auto;
    border: 20px solid #379683;
    border-radius: 100%;
}
.webtemplet1_content {
    padding-top: 80px;
    min-height: 100vh;
}
.navbar-righttemp1 {
    padding-right: 30px;

}
.navbar-righttemp1 li a {
    font-family: 'Roboto';
}
.selt_webtemplet1 p:nth-child(1) {
    margin-top: 15%;
    font-size: 20px;
    width: fit-content;
    background-color: #5cdb95;
    padding: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-weight: bolder;
}
.selt_webtemplet1 p:nth-child(2) {
    font-size: 50px;
    font-weight: bolder;
    color: #edf5e1;
}
.selt_webtemplet1 p:nth-child(3) {
    font-size: 20px;
    font-weight: bolder;
    color: #edf5e1;
}
.custom-shape-divider-bottom-1638111173 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1638111173 svg {
    position: relative;
    display: block;
    width: calc(201% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1638111173 .shape-fill {
    fill: #FFFFFF;
}
.webtemplet1_about_content {
    width: 100%;
    min-height: 70vh;
    background-color: white;
    padding-top: 50px;
}
.contact_section img,
.webtemplet1_about_content img {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.contact_section img {
    padding-top: 9%;
}
.contact_section h2,
.selt_about_webtemplet1 h2 {
    font-size: 30px;
    letter-spacing: 0.2em;
    font-weight: bolder;
    padding-bottom: 10px;
}
.selt_about_webtemplet1 p {
    font-size: 20px;
    text-align: justify;
    padding-right: 10%;
}
.webtemplet1_wave_content {
    width: 100%;
    min-height: 30vh;
}
.custom-shape-divider-top-1638112199 {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1638112199 svg {
    position: relative;
    display: block;
    width: calc(252% + 1.3px);
    height: 204px;
}

.custom-shape-divider-top-1638112199 .shape-fill {
    fill: #FFFFFF;
}
.webtemplet1__content {

    width: 100%;
    min-height: 50vh;
    padding-top: 6%;
}
.section_headert1 {
    text-align: center;
    font-size: 30px;
    letter-spacing: 0.2em;
    font-weight: bolder;
    color: white;
}
.webtemplet1__content h2 {
    padding-bottom: 30px;
}
.webtemplet1 .btn-descp {
    outline: none;
    background-color: transparent;
    margin-top: 5px;
    color: #379683;
    font-weight: bolder;
    border-radius: 25px;
    border-left-color: #379683;
    border-right-color: #379683;
    border-top-color: transparent;
    border-bottom-color: transparent;
}
.webtemplet1_expertise_content .researchareat1 {
    width: 100%;
    height: 40vh;
    border: 1px solid white;
    background-color: grey;

}

.skillratingt1 {
    margin: 10px;
}
.educationt1,
.experiecne1,
.workexp1,.ResearchAreat1,.traint1,.member1,.award1 {
    height: 30vh;
    padding: 10px;
    margin: 20px;
    border-radius: 50px;
    color: white;
    background-color: #1E1E25;
    box-shadow: 7px 7px 14px #000000, -7px -7px 14px #000000;
}
.ResearchAreat1{
    height: 25vh;
    width: 100%;
    padding: 20px 20px 0px 20px;
    border-radius: 25px;
   
    overflow-y:auto
}

  .ResearchAreat1::-webkit-scrollbar {
    width: 0px;
    border-top-right-radius: 50px !important;
  }
  
  /* Track */

  /* Handle */
  .ResearchAreat1::-webkit-scrollbar-thumb {
    background: #888;
  }

.traint1 {
    padding: 10px 10px 10px 7%;

}
.member1 {
    height: 33vh;
}
.award1 {
    height: 35vh;
}
.ResearchAreat1 h4{
    color:#dbfff8;
    font-size: 20px;
}
#researcharea{
    padding-bottom: 60px;
}
.award1 h3,
.educationt1 h3,
.experiecne1 h3,
.member1 h3,
.traint1 h3,
.workexp1 h3 {
    color: #3f903f !important;
}
.experiecne1 {
    height: 35vh;
}
.award1 img,
.educationt1 img,
.experiecne1 img,
.member1 img,
.workexp1 img {
    margin-top: 50%;
    width: 80%;
}
.modalt1 {
    color: black;
}
.timelinet1 {
    list-style: none;
    margin-top: 60px;
    position: relative;
}
.timelinet1-title,
.timelinet1-title a {
    font-weight: bold;
    color: white;
}

.text-muted {
    font-weight: 700;
}
.timelinet1:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 5px;
    background-color: rgb(172, 172, 172);
    left: 10%;
    margin-left: -1.5px;
}

.timelinet1 > li {
    margin-bottom: 20px;
    position: relative;
}
.timelinet1 > li:after,
.timelinet1 > li:before {
    content: " ";
    display: table;
}
.timelinet1 > li:after {
    clear: both;
}
.timelinet1 > li:after,
.timelinet1 > li:before {
    content: " ";
    display: table;
}
.timelinet1 > li:after {
    clear: both;
}
.t1t1 {
    padding-bottom: 50px;
}
.timelinet1 > li > .timelinet1-panel {
    width: 80%;
    float: left;
    margin-right: 50px;
    color: white;
    padding: 20px;
    position: relative;
    border-radius: 29px;
    background-color: #1E1E25;
    box-shadow: 7px 7px 14px #000000, -7px -7px 14px #000000;
}
.timelinet1 > li > .timelinet1-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}
.timelinet1 > li > .timelinet1-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

/*.timelinet1 > li > .timelinet1-badget1 {
    color: #fff;
    width: 80px;
    height: 80px;
    line-height: 50px;
    font-size: 1.4em;
  
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -38px;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }*/
.timelinet1 img {
    width: 10%;

    position: absolute;
    z-index: 123;
    left: 70%;

    padding: 15px;
    border-radius: 100px;

}
.timelinet1-inverted img {
    width: 10%;

    position: absolute;
    z-index: 123;
    padding: 15px;
    border-radius: 100px;
    left: 20%;
}
.timelinet1 > li > .timelinet1-badget1 {
    display: inline;
    font-size: 1em;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 6.5%;
    padding-top: 10px;
    margin-left: -38px;
    background-color: #5cdb95;
    padding: 40px;
    border-radius: 50px;
    z-index: 100;
}
#project_section1 {
    margin-top: 10%;
}
.timelinet1 > li.timelinet1-inverted > .timelinet1-panel {
    float: right;
}
.timelinet1 > li.timelinet1-inverted > .timelinet1-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}
.timelinet1 > li.timelinet1-inverted > .timelinet1-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timelinet1-badget1.primary {
    background-color: #2e6da4 !important;
}

.timelinet1-badget1.success {
    background-color: #3f903f !important;
}

.timelinet1-badget1.warning {
    background-color: #f0ad4e !important;
}

.timelinet1-badget1.danger {
    background-color: #d9534f !important;
}

.timelinet1-badget1.info {
    background-color: #5bc0de !important;
}

.timelinet1-title {
    margin-top: 0;
    color: inherit;
}

.timelinet1-body > p,
.timelinet1-body > ul {
    margin-bottom: 0;
}

.timelinet1-body > p + p {
    margin-top: 5px;
}
.modal {
    margin-top: 5%;
    height: 76vh;
    overflow: hidden;
}

.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown1 {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 180px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;

}

.dropdown-content a {
    color: black !important;
    padding: 10px;
    text-decoration: none;
    display: block;
}
.dropdown-content:after,
.dropdown-content:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.dropdown-content:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f1f1f1;
    border-width: 10px;
    left: 30%;
    margin-left: -19px;
}
.dropdown-content a:hover {
    background-color: #ddd;
    border-radius: 10px;
}
.dropdown1:hover .dropdown-content {
    display: block;
    border-radius: 10px;
}

.custom-shape-divider-top-1638198874 {
    position: absolute;

    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1638198874 svg {
    position: relative;
    display: block;
    width: calc(200% + 1.3px);
    height: 180px;
}

.custom-shape-divider-top-1638198874 .shape-fill {
    fill: #100E17;
}
.webtemplet1__content_contact {
    width: 100%;
    min-height: 50vh;
    background-color: rgb(255, 255, 255);
    padding-bottom: 30px;
}
.webtemplet1__content_contact .btn-descp {
    font-size: 20px;
}
.gap {
    width: 100%;
    padding: 5%;
}
.contact_section {
    padding-top: 15%;
}
.footertemp1 {
    width: 100%;
    min-height: 20vh;
    padding-top: 3%;

}

.footertemp1 p {
    color: white !important;
    font-size: 15px;

}
.footertemp1 h5 {

    font-size: 30px;
    color: white !important;
    font-family: 'Rock Salt' , cursive;
}
#navbaresponsive {
    display: none;
}
@media (max-width: 767px) {
    ul.timelinet1:before {
        left: 40px;
    }

    ul.timelinet1 > li > .timelinet1-panel {
        width: calc(100% - 100px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timelinet1 > li > .timelinet1-badget1 {
        left: -9%;
        margin-left: 0;
        top: 9%;
        padding: 30px;
        width: 20px ; 
    }

    ul.timelinet1 > li > .timelinet1-panel {
        float: right;
        margin-right:20px;
        margin-top: 20px;
    }
    ul.timelinet1 > li > .timelinet1-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -17px;
        right: auto;
    }
    ul.timelinet1 > li > .timelinet1-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -90px;
        right: auto;
    }
    .modal-backdrop,
    .navbartemp1 {
        display: none;
    }
    #navbaresponsive {
        display: block;
    }
    .navbarres {
        position: absolute;
        z-index: 12;
        display: inline;
    }
    .webtemplet1__content {
        padding-top: 30%;
    }

    .custom-shape-divider-bottom-1638111173,
    .custom-shape-divider-top-1638112199,
    .custom-shape-divider-top-1638198874,
    .webtemplet1_wave_content {
        display: none;
    }
    .award1,
    .educationt1,
    .experiecne1,
    .member1,
    .traint1,
    .workexp1,.ResearchAreat1 {
        height: auto !important;
    }
    .ResearchAreat1{
        padding-bottom: 20px;
    }
    .modalt1{
        margin-top: 50%;
    }
    .footertemp1 {
        width: 100%;
        min-height: 20vh;
        padding-top: 10%;
    
    }
    #webtemplet1_content_img {
        width: 65% ;
        border: 15px solid #379683; 
    }
}
.modal-backdrop {
    display: none;
}
#navbaresponsive {
    display: block;
}
.navbarres {
    width: 100%;
    padding: 20px;
    position: fixed;
    background-color: #100E17;
}
.navbarres a:nth-child(1) {
    width: 50%;
}
.navbarres #menures {
    font-size: 30px;
    color: white !important;
    position: absolute;
    right: 0;
    margin-right: 20px;
}
.sidenavt1 {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1002;
    top: 0;
    right: 0;
    background-color: #379683;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}
.sidenavt1 li {
    list-style-type: none;
    padding-top: 20px;
    font-weight: bolder;

}
.sidenavt1 a {
    padding: 8px 8px 0 32px;

    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    display: block;
    transition: 0.3s;
}
.sidenavt1 a:hover {
    color: #f1f1f1;
}

.sidenavt1 .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
