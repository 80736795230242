#passwordavailable,
#passwordnotavialable {
  margin: 10%;
  color: black;
}
#newpassword .btn{
width: 40% !important;
}
#resetbtn{
  margin-top: 30px;
  margin-left:50%;
  
}
#resetbtn .btn{
width: 65%;
outline:none
}
