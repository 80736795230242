#loaderm{
    background-color: #000000;
    height: 100vh;
    overflow: hidden;
}
#loaderm img{
    padding-top: 15%;
}
.notfound{
    padding:30px
}
.notfound img{

margin-top: 30px;
}
.notfound h4{
    text-align: center;
    font-size: 7em;
    padding-top: 10%;
    
}
.notfound .btn{
outline:none;
margin: 20px;
font-weight: bold;
border:2px solid
}