#cvtempreview,
.cvtempletshowpreview,
#websitetemplet {
  overflow-y: auto;
  height: 90vh;
  padding-bottom: 30%;
  overflow-x:scroll !important;
}

#cvpreview {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30%;
  overflow-y: scroll;
  height: 80vh;
  overflow-x:scroll !important;
}
#cvtempletpreview{
  border-radius: 30px;
  background: #ffffff;
  box-shadow:  -8px 8px 16px #f0f0f0,
  8px -8px 16px #ffffff;
  min-height:100vh;
  margin-left: 10%;

  overflow-x:scroll !important;
}
#demodownloadprintcv{
  position: absolute;
  z-index: 0;
}
#CVDetail{
  color:black
}
#cv1Section {
color:black !important;
font-size: 15px;
}
#cv1Section table{
  border:1px solid black;
  color:black !important;
}
.cvtemplet-box {
  margin: 10%;
}
.cvtemplet-box img {
  transition: 0.5s;
  cursor: pointer;
  border: 2px solid;
  border-radius: 10px;
  height: 50vh;
}
.cvtemplet-box:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.cvtemplet-box {
  display: block;

  margin-bottom: 40px;
  -webkit-transition: 0s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.cvtemplet-box .desc {
  position: absolute;
  bottom: 20px;
  left: 40px;
  right: 20px;

  padding: 1.5em 1em;
  opacity: 0;
  height: 105px;
  overflow: hidden;
  transition: 0.3s;
}
.cvtemplet-box:hover .desc {
  opacity: 1;
  margin-top: 10px;
  height: 150px;
  transition: 0.3s;
}
.cvtemplet-box .desc .btn {
  margin-left: 30px;
  background-color: #0c213a;
  color: white;
  font-weight: bolder;
  outline: none !important;
}
.cvtempletshowpreview {
  padding-top: 20px;
}
.cvtempletshowpreview img {
  width: 80%;
  margin-left: 30px;
  border: 1px solid;
  border-radius: 10px;
}
.cvtempletshowpreview .btn {
  margin: 20px;
  margin-top: 25%;
  color: #ffffff;
  background-color: #2d6dbb;
  padding: 20px;
  font-size: 20px;
}
.templetcopllapse {
    z-index: 12;
    background-color: snow;
  }
  .templetcopllapse .btn {
    background-color: white;
  }

.glyphicon-cog {
  font-size: 20px;

  color: rgb(12, 33, 58);
}
#watermark {
display: none;
  position: relative;

}

#watermark p {
  position: absolute;
  margin: 55%  25%   25%  25%  ;
  color: rgb(0, 0, 0);
opacity: 0.1;
text-align: center;
  font-size:150px;
  pointer-events: none;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}
@page {
  size: A4;
  margin: 11mm 3mm 20mm 3mm;
}
@media print {
  #watermark {
    position: fixed;
    padding-top: 30%;
  }

}
