@media (max-width: 768px) {
    #dashboardmain {
        overflow: hidden;
        height: 100vh;
    }
    .leftSection {
        float: left;
        background-color: rgb(0, 0, 0);
        padding-bottom: 10%;
        padding-top: 10px;
        position: absolute;
        z-index: 12;
    }
    .middleSection {
        width: 100%;
        float: left;
        background-color: rgb(255, 255, 255);
        position: relative;

    }
    .menu {
        display: block !important;
        color: black;
        border: 1px solid black;
        width: fit-content;
        padding: 5px;
        margin-top: 15px;
        margin-left: -50%;
        background-color: white;
        font-weight: bolder;
        border-radius: 25PX;
        font-size: 20px;

    }
    #addsection a {

        margin-top: 10px;
        border-radius: 25px;

    }
    .topNavbar .navbar-right {
        display: flex !important;
        position: absolute;
        right: 0;
        margin-right: 10px;

    }
    .leftsidemenuList .glyphicon1 {
        font-size: 30px;
        padding-bottom: 15px;
        margin-left: 90%;
        display: block !important;
    }
    .mainSectionMiddle {
        height: 90vh !important;
        overflow-y: scroll !important;
        padding-bottom: 20% !important;
    }
    .rightSection {
        display: none !important;
    }
    #addsection,
    #userIcontop {
        padding-top: 6px;
    }
    #userIcontop .dropdown-menu {
        margin-left: -300% !important;
        position: absolute;
        background-color: white;
        border: 1px solid black;
    }
    .frinendlistsection {
        height: 80vh;
        width: 70%;

    }
    .chatsection {
        width: 75%;
        margin: 45% 0 10% 10%;
    }
   .tourbtn{
       display:none !important;
   }
   #responwarn{
    display:block
}
    
}
.leftsidemenuList .glyphicon1 {
    display: none;
}
.menu {
    display: none;
}
@media (min-width: 500px) and (max-width:1024px) {
    .leftSection {
        width: 20% !important;
    }
    .middleSection {
        width: 80%;
        
    }
    .mainSectionMiddle {
        margin-top: 10vh;

    }
    .rightSection {
        width: 0%;
    }
    .middleSection .topNavbar {
        height: 7vh;
    }
    .middleSection .topNavbar  li{
        padding:10px 30px 10px 10px
    }
    .middleSection .bottomNavbar {
        height: 7vh;
    }
    .dashbox {
        width: 100%;
        height: 30vh; 
    
    }
    .frinendlistsection {
        height: 80vh;
        width: 70%;

    }
    .chatsection {
        width: 75%;
        margin: 45% 0 10% 10%;
    }
}